<template>
  <div class="legal-recharge">
    <van-config-provider>
      <van-nav-bar :title="pageTitle" left-arrow @click-left="goBack" :border="false"></van-nav-bar>
    </van-config-provider>
    
    <van-tabs v-model:active="tabActive" :type="$AppData.config.themeType ? 'card' : 'line'">
      <van-tab :title="$t('tabTitle.buyCoins')">
        <div class="recharge-content">
          <div class="form-wrap">
           <div class="form-title">{{$t('field.bankCardNumber')}}</div>
            <div class="field-wrap">
              <van-field
                :model-value="activeBankcardIndex != null ? bankcardSelect[activeBankcardIndex] : ''"
                class="form-field with-suffix"
                size="large"
                @click="handleBankPicker"
                readonly
              />
              <div class="suffix-wrap" @click="handleBankPicker">
                <img class="triangle" src="/images/triangle.png">
              </div>
            </div>
            <div class="form-title">{{$t('field.buy')}}{{$AppData.config.appCoin}}</div>
            <div class="field-wrap">
              <van-field
                v-model="amount"
                @input="inputAmount"
                class="form-field with-suffix"
                type="number"
                size="large"
                :placeholder="$t('placeholder.default')"
              />
              <div class="suffix-wrap">
                <span>{{$AppData.config.currency ? $AppData.config.currency.toUpperCase() : ''}}</span>
              </div>
            </div>
          </div>
          <div class="page-button-wrap">
            <van-button
              size="large"
              type="primary"
              block
              @click="handleInitLegalRecharge"
              :disabled="activeBankcardIndex === null || !amount"
            >{{$t('button.submit')}}</van-button>
          </div>
          <router-link class="history-btn" :to="{ name:'legalRechargeHistory' }">{{$t('title.historyData')}}</router-link>
          <div class="tip-text">{{appTip}}</div>
        </div>
        <van-popup v-model:show="showBankPicker" position="bottom">
          <van-picker
            :columns="bankcardSelect"
            @confirm="onBankConfirm"
            @cancel="showBankPicker = false"
          />
        </van-popup>
        <van-popup v-model:show="showRechargeInfo" :close-on-click-overlay="false" position="bottom">
          <div class="recharge-info-title">
            {{$t('field.appraisal')}}
            <span @click="showRechargeInfo = false">{{$t('common.cancel')}}</span>
          </div>
          <div>
            <van-cell :border="false" :title="$t('field.payAmount')" :value="`${preRechargeData.payAmountStr} ${preRechargeData.payCurrency}`" />
            <van-cell :border="false" :title="$t('field.receivedAmount')" :value="`${preRechargeData.amountStr} ${preRechargeData.currency}`" />
            <van-cell :border="false" :title="$t('field.exchangeRate')" :value="`${preRechargeData.exchangeRateStr} : 1`" />
          </div>
          <div class="pop-button-wrap">
            <van-button
              size="large"
              type="primary"
              block
              @click="handleLegalRecharge"
            >{{$t('button.confirm')}}</van-button>
          </div>
        </van-popup>
      </van-tab>
      <van-tab :title="$t('tabTitle.rechargeProcessing')">
        <van-pull-refresh v-model="refreshing" @refresh="refreshData">
          <div class="order-list">
            <van-list
              v-if="dataList.length > 0"
              v-model:loading="loading"
              :finished="finished"
              :finished-text="$t('common.noMore')"
              @load="loadData"
            >
              <div class="order-block" v-for="item in dataList" :key="item.sn" @click="goToDetail(item)">
                <div class="order-sn">{{item.sn}}</div>
                <div class="order-amount">{{item.amountStr}}PHP</div>
                <div class="order-time">{{item.createTimeStr}}</div>
                <div class="order-status">{{item.statusStr}}</div>
              </div>
            </van-list>
            <div v-else>
              <van-empty :description="$t('common.noData')"></van-empty>
            </div>
          </div>
        </van-pull-refresh>
      </van-tab>
    </van-tabs>
      
  </div>
</template>

<script>
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import i18n from '@/assets/lang/index';
import { initLegalRecharge, createLegalRecharge, queryRechargeBankcardList, queryLegalRechargeHanding } from "@/api";

export default {
  setup() {
    const router = useRouter();
    const goBack = () => router.back();
    
    const tabActive = ref(0);
    const loading = ref(false);
    const finished = ref(false);
    const refreshing = ref(false);
    const dataList = ref([]);
    
    const showBankPicker = ref(false);
    const showRechargeInfo = ref(false);
    return {
      goBack,
      tabActive,
      loading,
      finished,
      refreshing,
      dataList,
      showBankPicker,
      showRechargeInfo,
    };
  },
  
  data() {
    return {
      pageTitle: localStorage.getItem('pageName'),
      amount: '',
      appTip: '',
      current: 1,
      bankcardList: [],
      bankcardSelect: [],
      preRechargeData: {},
      activeBankcardIndex: null,
    };
  },
  
  mounted() {
    // if(!this.userInfo.hasTradePassword) {
    //   this.$myDialog.confirm({
    //     title: i18n.global.t('alert.default'),
    //     message: i18n.global.t('alert.noTradePwd'),
    //   })
    //   .then(() => {// on confirm
    //     this.$router.replace('/security/editTradePwd')
    //   })
    //   .catch(() => {// on cancel
    //     this.$router.back();
    //   });
    // }
    this.fetchBankList();
    this.fetchOrderList();
  },

  methods: {
    fetchBankList() {
      queryRechargeBankcardList({pageSizeStr: 100, pageNumberStr: 1}).then(res => {
        this.bankcardList = res.data || [];
        this.bankcardSelect = this.bankcardList.map(item => this.formatBankcard(item));
      });
    },
    fetchOrderList() {
      queryLegalRechargeHanding({
        pageSizeStr: 100,
        pageNumberStr: this.current,
      }).then(res => {
        this.dataList = this.dataList.concat(res.data || []);
        this.current = res.pageNumber + 1;
        this.loading = false;
        if (res.totalNumber <= res.pageNumber * res.pageSize) {
          this.finished = true;
        }
      });
    },
    loadData() {
      if (this.refreshing) {
        this.dataList = [];
        this.current = 1;
        this.refreshing = false;
      }
      this.fetchOrderList();
    },
    refreshData() {
      // 清空列表数据
      this.finished = false;
            
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      this.loadData();
    },
    formatBankcard(bankcard) {
      let bankCardNo = bankcard.bankCardNo || '';
      if(bankCardNo > 4) {
        bankCardNo = '**' + bankCardNo.substring(bankCardNo.length - 4, bankCardNo.length);
      }
      return `${bankcard.bankName}(${bankCardNo})`
    },
    handleBankPicker() {
      if(this.bankcardList.length > 0) {
        this.showBankPicker = true;
      } else {
        this.$myDialog.confirm({
          title: i18n.global.t('alert.warnning'),
          message: i18n.global.t('alert.addRechargeBankCard'),
        })
        .then(() => {
          this.$router.push('/bank/editAccount');
        })
        .catch(() => {
          this.goBack();
        });
      }
    },
    onBankConfirm(value, index) {
      this.activeBankcardIndex = index;
      this.showBankPicker = false;
    },
    inputAmount() {
      this.amount = this.amount.downFixed(4);
    },
    handleInitLegalRecharge() {
      initLegalRecharge({
        payAmount: this.amount,
      }).then(res => {
        this.preRechargeData = res.data;
        this.showRechargeInfo = true;
      });
    },
    handleLegalRecharge() {
      createLegalRecharge({
        bankcardId: this.bankcardList[this.activeBankcardIndex].id,
        token: this.preRechargeData.token,
      }).then(res => {
        localStorage.setItem('legalRechargeData', JSON.stringify(res.data));
        this.$router.push('/legal/recharge/detail');
      });
    },
    goToDetail(item) {
      if(!item.yieldCardNo) {
        return false;
      }
      localStorage.setItem('legalRechargeData', JSON.stringify(item));
      this.$router.push('/legal/recharge/detail');
    },
    goBack() {
      this.$router.back();
    },
  },
};
</script>

<style lang="less">
.legal-recharge {
  padding-bottom: 80px;
  .van-tabs__line {
    background: var(--van-primary-color);
  }
  .van-tab {
    &.van-tab--active {
      color: var(--van-primary-color);
    }
    .van-tab__text {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
    }
  }
  .recharge-content {
    padding: 16px;
    .form-wrap {
      padding: 0 12px 16px;
      background: #fff;
      border-radius: 6px;
      overflow: hidden;
      .form-title {
        margin: 16px 0;
        font-size: 14px;
        color: #28293D;
        font-weight: 400;
      }
      .field-wrap {
        position: relative;
        .suffix-wrap {
          position: absolute;
          top: 0;
          right: 12px;
          width: 110px;
          line-height: 46px;
          text-align: right;
          font-weight: 400;
          color: #6F6F93;
          span {
            font-size: 16px;
            vertical-align: middle;
          }
          .triangle {
            width: 8px;
            margin-left: 12px; 
            vertical-align: middle;
          }
        }
      }
      .form-field {
        font-size: 16px;
        font-weight: 500;
        color: #000;
        background: #F9FAFB;
        border-radius: 4px;
        &.with-suffix {
          padding-right: 110px;
        }
      }
      .field-extra {
        padding: 8px 0;
        font-size: 10px;
        color: #6F6F93;
        line-height: 12px;
        font-weight: 400;
        .highlight {
          color: var(--van-primary-color);
        }
      }
    }
    .page-button-wrap {
      // position: fixed;
      // left: 0;
      // bottom: 0;
      // width: 100%;
      padding: 16px 0;
      // background: #fff;
      box-sizing: border-box;
    }
    .tip-text {
      padding: 16px;
      font-size: 12px;
      color: #646566;
    }
  }
  .recharge-info-title {
    margin: 0 15px;
    line-height: 60px;
    font-size: 18px;
    color: #000000;
    font-weight: 600;
    border-bottom: 1px dashed #efefef;
    span {
      display: block;
      float: right;
      font-size: 14px;
      color: #6F6F93;
      text-align: left;
    }
  }
  .pop-button-wrap {
    width: 100%;
    padding: 36px 16px 16px;
    box-sizing: border-box;
  }
  .history-btn {
    display: block;
    padding: 10px;
    font-size: 14px;
    color: #6F6F93;
    text-align: center;
  }
}
.theme1 {
  .legal-recharge {
    .van-tabs--card {
      padding-top: 20px;
    }
    .van-tabs__content {
      margin: 0 16px;
      background: #fff;
      border-radius: 0 0 9px 9px;
      box-shadow: 0px 4px 16px 0px #EBEBEB;
    }
    .recharge-content {
      .form-wrap { padding: 0;}
    }
  }
}
</style>
